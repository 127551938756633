<template>
    <v-container fluid class="mx-auto">
        <v-row class="mt-10">
            <v-col cols="12" offset-lg="9" lg="3" class="d-flex justify-lg-end justify-md-end">
                <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn block large rounded v-bind="attrs" v-on="on">New Merchant</v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="d-flex justify-center">Create Merchant</v-card-title>

                        <v-card-text>
                            <v-form>
                                <v-select solo rounded clearable dense :items="items"
                                    v-model="merchant.institution_code" label="Institution Name">
                                </v-select>
                                <v-text-field solo rounded clearable dense v-model="merchant.account_number_vodacom"
                                    label="Vodacom Number"></v-text-field>
                                <v-text-field solo rounded clearable dense v-model="merchant.account_number_airtel"
                                    label="Airtel Number"></v-text-field>
                                <v-text-field solo rounded clearable dense v-model="merchant.account_number_orange"
                                    label="Orange Number"></v-text-field>
                                <v-text-field solo rounded type="number" min="0" max="100" dense
                                    v-model="merchant.vodacom_debit_comission" label="Vodacom Debit Commission(%)">
                                </v-text-field>
                                <v-text-field solo rounded type="number" min="0" max="100" dense
                                    v-model="merchant.vodacom_credit_comission" label="Vodacom Credit Commission(%)">
                                </v-text-field>
                                <v-text-field solo rounded type="number" min="0" max="100" dense
                                    v-model="merchant.airtel_debit_comission" label="Airtel Debit Commission(%)">
                                </v-text-field>
                                <v-text-field solo rounded type="number" min="0" max="100" dense
                                    v-model="merchant.airtel_credit_comission" label="Airtel Credit Commission(%)">
                                </v-text-field>
                                <v-text-field solo rounded type="number" min="0" max="100" dense
                                    v-model="merchant.orange_debit_comission" label="Orange Debit Commission(%)">
                                </v-text-field>
                                <v-text-field solo rounded type="number" min="0" max="100" dense
                                    v-model="merchant.orange_credit_comission" label="Orange Credit Commission(%)">
                                </v-text-field>
                            </v-form>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn text color="success" :loading="loading" @click="create">Create Merchant</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="red" text @click="dialog = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card rounded="xl">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="merchants" :search="search">
                            <template v-slot:top>
                                <v-text-field solo rounded v-model="search"
                                    label="Search an merchant name/merchant code" class="mx-4 pt-4"
                                    append-icon="mdi-magnify"></v-text-field>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-dialog persistent v-model="dialogInfo" width="500">
                    <template>
                        <v-card rounded="lg">
                            <v-card-title class="d-flex justify-center">Creation Reussie !</v-card-title>
                            <v-card-text class="text-h6 text-center p-5 m-2">
                                {{ merchantInfo }}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogInfo = false">
                                    Close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import { mapActions, mapState } from "vuex";

import services from "../services/services";


export default {
    name:"Merchants",
    data: () => ({
        dialog: false,
        dialogInfo: false,
        items: [],
        merchantInfo: "",
        headers: [
            { text: "Institution Name", value: "institution_name", align: "center" },
            { text: "Institution Code", value: "institution_code", align: "center" },
            { text: "Merchant Code", value: "merchant_code", align: "center" },
            { text: "Merchant ID", value: "merchant_id", align: "center" },
            { text: "Created At", value: "created_at", align: "center" },
        ],
        search: "",
        merchants: [],
        loading: false,
        merchant: {
            institution_code: "",
            account_number_vodacom: "",
            account_number_orange: "",
            account_number_airtel: "",
            vodacom_debit_comission: "",
            vodacom_credit_comission: "",
            orange_debit_comission: "",
            orange_credit_comission: "",
            airtel_debit_comission: "",
            airtel_credit_comission: ""
        }
    }),
    methods: {
        ...mapActions(['setInstitutions']),
        async create() {
            this.loading = true;

            for (let i = 0; i < this.institutions.length; i++) {

                if (this.merchant.institution_code === this.institutions[i].institution_name) {
                    this.merchant.institution_code = this.institutions[i].institution_code;
                    break;
                }
            }

            const response = await services.create_merchant(this.merchant);
            this.dialog = false
            this.dialogInfo = true

            this.merchantInfo += `Merchant Secret : ${response.resultData.merchant_secrete}\n`
            this.merchantInfo += "\n"

            this.loading = false;
        }
    },
    computed: {
        ...mapState(['institutions'])
    },
    async mounted() {
        this.merchants = await services.get_merchants();

        await this.setInstitutions();
        this.items = this.institutions.map(el => {
            return el.institution_name == null ? "" : el.institution_name
        });
        this.items.sort();


    }
}
</script>


<style scoped>
</style>